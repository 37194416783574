<template>
  <div class="plan py-2 mb-5">
    <div class="row">
      <div class="col-12 text-center">
        <h5 class="font-weight-bold text-muted">
          Conoce nuestros planes para ti
        </h5>
      </div>
      <div class="col-12 text-center">
        <p class="lead">Escoge el que mejor se adapte a tus necesidades</p>
      </div>
      <div class="col-12 text-center">
        <v-carousel :data="planData" @showModal="(evt) => handleShowModal(evt)"
          @stepNext="(evt) => next(evt.total, evt.type, evt.packageId)" />
      </div>
      <div style="text-align:right">
      <a href="javascript:void(0)" class="btn btn-primary mt-6" @click="getPDF()" >Descargar Cotización</a>
    </div>
    </div>

    <a-modal v-model="visible" title="Coberturas" :mask="true" :width="700">
      <template slot="footer">
        <a-button class="btn btn-primary" key="back" @click="handleCancel">
          Entendido
        </a-button>
      </template>

      <div class="container-fluid mt-1 mb-5 table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <th scope="column">
                <span class="font-weight-light" style="font-size: 20px">Descripción de Cobertura</span>
              </th>
              <td>
                <div class="text-center">
                  <b>Monto Asegurado <br />($ MXN)</b>
                </div>
              </td>
            </tr>

            <tr v-for="(f, index) in features" v-bind:key="index">
              <th scope="row">
                <span class="d-block">{{ f.section }} - {{ f.cobertura }}</span>
              </th>
              <td>
                <div class="text-center check">
                  {{ f.price }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
      
    </a-modal>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VCarousel from "../base/VCarousel.vue";
import { firestore } from "@/services/firebase.service";
import { zoneCosts, convertToDollar, getQuotePDF } from "@/services/costco.service";
import { localeData } from "moment";
export default {
  data() {
    return {
      // plans: []
      plan: {},
      costs: [0, 0, 0],
      costsZone1: 0,
      costsZone2: 0,
      costsZone3: 0,
      isZoneCastratophic: false,
      totalCat: 0,
      totalNoCat: 0,
      visible: false,
      totalPremium: 0,
      mensualidadCat: 0,
      mensualidadNoCat: 0,
      planData: {},
      isAlfa1: false,
      features: [],
      currentSlide: 0,
    };
  },
  props: ["zipCode", "package"],

  created() { },
  mounted() {
    this.getZoneCost();
    this.getPlan();
  },

  components: {
    VCarousel,
    
  },
  computed: {
    ...mapGetters({
      plans: "plans/ordered",
    }),
    query() {
      return this.$route.query;
    },
  },

  methods: {
    handleCancel() {
      this.visible = false;
    },
    handleShowModal(plan) {
      try {
        const handleAccents = (text) => {
          if (text == "Huracan") {
            return "Huracán";
          } else if (text == "Terremoto y/o erupcion volcanica") {
            return "Terremoto y/o erupción volcánica";
          } else if (text == "Remocion de escombros") {
            return "Remoción de escombros";
          } else if (text == "Gastos medicos resultantes de un accidente") {
            return "Gastos médicos resultantes de un accidente";
          } else if (text == "Equipo electronico y/o electrodomestico") {
            return "Equipo electrónico y/o electrodoméstico";
          } else if (text == "Articulos en transito") {
            return "Artículos en tránsito";
          } else if (text == "R.c. sin comprobacion via judicial") {
            return "Responsabilidad civil sin comprobación via judicial";
          } else if (text == "Perdida y/o robo de llaves") {
            return "Pérdida y/o robo de llaves";
          } else if (text == "Perdida de rentas") {
            return "Pérdida de rentas";
          } else if (text == "Perdida y/o robo de documentos") {
            return "Pérdida y/o robo de documentos";
          } else {
            return text;
          }
        };

        if (plan.type == "CAT") {
          const featureCat = this.planData.CAT;
          this.features = featureCat.features
            .filter((item) => item.coverage != null)
            .map((item) => {
              item.cobertura = item.coverage; //handleAccents(cob);
              item.price = item["secureAmountPackage" + (plan.idx + 1)] != "AMPARADA"
              ?
              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item["secureAmountPackage" + (plan.idx + 1)])
              :
              "AMPARADA"; //handleAccents(sec);
              return {
                ...item,
              };
            });
          this.visible = true;
        }
        if (plan.type == "NoCat") {
          const featuresNoCat = this.planData.NoCAT;
          this.features = featuresNoCat.features
            .filter((item) => item.coverage != null)
            .map((item) => {
              item.cobertura = item.coverage; //handleAccents(cob);
              item.price = item["secureAmountPackage" + (plan.idx + 1)] != "AMPARADA"
              ?
              new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(item["secureAmountPackage" + (plan.idx + 1)])
              :
              "AMPARADA"; //handleAccents(sec);
              return {
                ...item,
              };
            });
          this.visible = true;
        }
      } catch (a) {
        console.log(a);
      }
    },
    next(amount, cat, id) {
      const monthAmount = Number((amount / 12).toFixed(2));
      const selectPlan = { amount, cat, monthAmount, packageId: id };
      localStorage.setItem("p", JSON.stringify(selectPlan));

      this.$gtag.event("plan_selected_" + cat, {
        event_category: "quote",
        event_label: "Plan selected",
        value: 1,
      });

      this.$emit("go");
    },
   

    formatterAmount(value) {
      return `$ ${new Intl.NumberFormat("es-MX", {
        currency: "MXN",
        maximumFractionDigits: 10,
      }).format(value)}`;
      // return `${value}%`;
    },

    async getZoneCost() {
      try {
        const data = await zoneCosts(this.zipCode);

        // const  secureAmount = Number(localStorage.getItem('secureAmount'))

        const dataZone = localStorage.getItem("zones") || null;

        const zones = JSON.parse(dataZone);

        // console.log('dataZone::dataZone::dataZone',zones.zona_hidro);

        let zona_hidro = zones.zona_hidro;

        if (zona_hidro <= 4) {
          this.isAlfa1 = true;
        }

        // let zona_sismica = data.data.zones.zona_sismica || 1

        // await this.getPlan(zona_hidro,zona_sismica, secureAmount)

        // this.costs = data.costs
        // // this.costsZone1 = data.costs[0]
        // // this.costsZone2 = data.costs[1]
        // // this.costsZone3 = data.costs[2]
        this.isZoneCastratophic = data.data[0].isZoneCastratophic;
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    },

    async getPlan() {
      try {
        this.planData = JSON.parse(localStorage.getItem("planData") || null);

        //  console.log(planData);

        const featuresNoCat = this.planData.NoCAT;
        const featuresCat = this.planData.CAT;

        this.totalCat = featuresCat.total;
        this.totalNoCat = featuresNoCat.total;

        this.features = featuresNoCat.features
          .filter((item) => item.visible == true)
          .map((item, idx) => {
            const str = item.feature.toLowerCase();
            const strSeccion = item.seccion.toLowerCase();
            item.feature = str.charAt(0).toUpperCase() + str.slice(1);
            item.seccion =
              strSeccion.charAt(0).toUpperCase() + strSeccion.slice(1);

            return {
              ...item,
            };
          });

        await this.calculateRateConvetion();
      } catch (a) {
        console.log(a);
      }
    },

    async calculateRateConvetion() {
      const rateValue = await convertToDollar();
      const planData = JSON.parse(localStorage.getItem("planData"));
      const premiumCat = planData.CAT.total;
      const premiumNoCat = planData.NoCAT.total;
      const convertionValueCat = premiumCat / rateValue;
      const convertionValueNoCat = premiumNoCat / rateValue;
      localStorage.setItem(
        "ratesValues",
        JSON.stringify({ convertionValueCat, convertionValueNoCat })
      );
    },

    getPriceCost(feature, idx) {
      if (feature.costs) {
        return this.formatterAmount(feature.costs[idx]);
      }

      if (feature.values) {
        return feature.values[idx];
      }

      return "-";
    },

    printFilter() {
      // console.log(this.filter);
    },

    getBillingFrequencyAbbr(frequency) {
      switch (frequency) {
        case "daily":
          return "day";
        case "weekly":
          return "week";
        case "monthly":
          return "month";
        case "yearly":
          return "year";
      }
    },
    async getPDF() {
      const plans = JSON.parse(localStorage.getItem('planData'))
      const zones = JSON.parse(localStorage.getItem('zones'))
        this.isDownloading = true
        getQuotePDF(plans.quoteId,this.query.postalCode,zones.isZoneCastratophic, "CONDOMINIO_ACAPULCO")
          .then((response) => {
              var fileURL = window.URL.createObjectURL(
              new Blob([response.data], {
                type: `application/pdf`,
              })
            )
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', 'Cotizacion.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()
          })
          .catch(console.error)
          .finally(() => (this.isDownloading = false))
      }
  },
  
};
</script>

<style lang="scss">
// .VueCarousel-wrapper {
//   padding-bottom: 1rem;
// }

.check i {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  // background-color: #b9f6ca82;
  line-height: 38px;
  font-size: 27px;
  color: #2674b4;
  font-weight: 300;
}
</style>